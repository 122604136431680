// Here you can add other styles
.card {
    -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: white;
}

.table.dataTable {
    width: 100% !important;
}

.flatpickr-clear {
    cursor: pointer;
    background-color: white;
}

.flatpickr-clear:hover {
    background-color: whitesmoke;
}

.logo {
    font-size: 70px;
    color: green;
}

.semicolon::before {
    content: ': ';
}

.fc-day-grid-event .fc-content {
    color: white;
}

.nav-dropdown-items .nav-item .nav-link {
    font-size: 12px;
}

.nav-ais .nav-link .nav-icon {
    margin-top: 0.10rem;
    width: auto;
}

.nav-ais .nav-link {
    display: flex !important;
}